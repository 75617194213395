import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import { sendEmail } from "./api";
import styled from "@emotion/styled";
import { useMutation } from "react-query";
import { CircularProgress } from "@mui/material";

type MenuItem = { title: string; content: string };

const StyledInput = styled.input`
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 30px;
  line-height: 2rem;
  padding: 0.2em 1em;

  &:focus-visible {
    outline: none;
  }

  @media (max-width: 868px) {
    width: 150px;
  }
`;

const MenuItemData: MenuItem[] = [
  {
    title: "Home",
    content: `Welcome to Naonow B2B Tech department!
      You will find below a diaporama of framed pictures our main points of attraction (From left to right):
      <ul>
      <li>Naonow Ludotheque Concept</li>
      <li>Naonow International Market</li>
      <li>Naonow Culture Fondations</li>
      <li>Naonow Creativity Design</li>
      <li>Naonow Shenzhen Company</li>
      </ul>
      `,
  },
  {
    title: "News",
    content:
      "Contact Naonow to know more about where and what we will show up next. Follow Naonow on your favorite social media or let us your E-mail to stay tuned in private. You can find here, at once, every ways possible to join us and nowhere else.",
  },
  {
    title: "Partnership",
    content:
      "At Naonow, we partner mostly with Start-ups for the high-tech but not only, some big actors of the local market trust us already, mainly for our creative and cultural design. To be part of the future and within the first to be contacted as supplier, client or investor to develop Naonow’s new ideas, it’s up to you. On regular basis or punctually, let’s meet first, Naonow team is the flexible one to fit and fix your need, because we cherish face to face overall. As you may figured out, that's all about Naonow B2B Tech Department.",
  },
  {
    title: "Membership",
    content:
      "For Naonow to celebrate loyalty and trust on both side, we are using membership. Yearly or seasonal ones, to fit your wallet and fix the days on your agenda, so you can organise yourself easier and better. For now, memberships are only made for personal or company coaching and franchise training, which belong to Naonow B2C Fam Department.",
  },
  {
    title: "Concept",
    content:
      "Everyone in Naonow is here to innovate in term of design, ergonomics, playability and technology, to propose products and ideas that will survive the trends while bringing a plus into the society. We think big, far and fun. The excellence of Naonow’s product is to make their users Learn something at the end while enjoyable enough to being shared voluntarily which make it playable without guilt for endless times.",
  },
  {
    title: "FaQ",
    content: `<h4>What is the difference between Teal and Purple background?</h4>
      The first one is for the Tech department or B2B, while the other is for the Fam department or B2C.
      <h4>How to use the feature of this movable background?</h4>
      Click and drag the handle to slide it toward the side you prefer, then let it there to search into the framed subject you are here for.
      <h4>What if I can use click and drag function?</h4>
      Enter in your browser one of the following address: www.naonow.fun (purple B2C background showing up) www.naonow.tech (for the teal B2B
      background)
      <h4>Why nothing else come after clicking on a framed picture?</h4>
      Maybe you disable the pop-up window option in your browser. If it’s not about that, we suggest you to contact us so we can take corrective action and answer all your question by the way.`,
  },
];

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const emailRegExp = new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 868);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentMenuItem, setCurrentMenuItem] = React.useState<MenuItem>(
    MenuItemData[0]
  );
  const [email, setEmail] = useState<string>("");
  const isMenuOpen = anchorEl?.getAttribute("data-type") === "menu";
  const isContactOpen = anchorEl?.getAttribute("data-type") === "contact";
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (event.currentTarget.getAttribute("data-type") === "contact") {
      setTimeout(() => inputRef.current?.focus());
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMenuItemClick = (item: MenuItem) => {
    setCurrentMenuItem(item);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const sendEmailMutation = useMutation("sendEmail", sendEmail, {
    onError: () => {
      alert("Failed to send email");
    },
  });
  const handleConfirmEmailAddress = async () => {
    const result = window.confirm(
      `Please confirm your email address: \n ${email}`
    );
    if (result) {
      handleSendEmail();
    }
  };
  const handleSendEmail = async () => {
    // await sendEmailMutation.mutateAsync(email ?? "");
    await Promise.resolve();
    setEmail("");
    handleClose();
    alert("Thanks for reaching out. \nWe will contact you soon!");
  };
  const isEmailValid = emailRegExp.test(email);

  return (
    <Box className="header">
      <Typography
        sx={{
          fontFamily: "Whipsnap",
          fontSize: "5rem",
          lineHeight: 1.2,
          color: "#fae231",
          marginLeft: 1,
          marginRight: 5,
        }}
      >
        Naonow
      </Typography>
      <div className="buttons">
        <Button
          sx={{ color: "white", fontWeight: 700, fontSize: 18 }}
          endIcon={!isMobile && <ExpandMoreIcon />}
          variant="text"
          data-type="menu"
          aria-controls={isMenuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
          onClick={handleClick}
        >
          {isMobile ? <MenuIcon /> : "Menu"}
        </Button>
        <Popover
          anchorEl={anchorEl}
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={isMenuOpen}
          onClose={handleClose}
        >
          <Box className="menu">
            <Box className="menu-items">
              {MenuItemData.map((item, index) => (
                <Link
                  className={`menu-item ${
                    currentMenuItem.title === item.title ? "active" : ""
                  }`}
                  onClick={() => onMenuItemClick(item)}
                  key={index}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
            <Box className="menu-content">
              <div
                dangerouslySetInnerHTML={{ __html: currentMenuItem.content }}
              />
            </Box>
          </Box>
        </Popover>
        <Button
          sx={{
            color: "white",
            fontWeight: 700,
            fontSize: 18,
          }}
          endIcon={!isMobile && <ExpandMoreIcon />}
          data-type="contact"
          variant="text"
          aria-controls={isContactOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isContactOpen ? "true" : undefined}
          onClick={handleClick}
        >
          {isMobile ? <EmailIcon /> : "Join the games"}
        </Button>
        <Popover
          anchorEl={anchorEl}
          className="contact-popover"
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              left: "200px",
            },
          }}
          open={isContactOpen}
          onClose={handleClose}
        >
          <Box className="email">
            <label htmlFor="email-field">Email</label>
            <StyledInput
              ref={inputRef}
              value={email}
              onChange={handleEmailChange}
              required
              type="email"
              id="email-field"
            />
            <IconButton
              sx={{ padding: 0.5 }}
              disabled={!isEmailValid}
              onClick={handleConfirmEmailAddress}
            >
              {sendEmailMutation.isLoading ? (
                <CircularProgress color="success" />
              ) : (
                <CheckCircleOutlineIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        </Popover>
      </div>
    </Box>
  );
};

export default Header;
