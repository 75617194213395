import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyeldLinkGroup = styled(Box)({
  "& > a": {
    marginRight: "2em",
  },
});

const Footer = () => {
  return (
    <Box className="footer">
      <Box className="links">
        <StyeldLinkGroup className="left">
          <Link>Linkedin</Link>
          <Link>Facebook</Link>
          <Link>Wechat</Link>
          <Link>小红书</Link>
        </StyeldLinkGroup>
        <StyeldLinkGroup
          className="right"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Link>Legal</Link>
          <Link>Privacy</Link>
          <Link sx={{ display: "flex", alignItems: "center" }}>
            Contact us{" "}
            <Typography
              sx={{
                fontFamily: "Whipsnap",
                fontSize: 26,
                lineHeight: 1.2,
                color: "yellow",
                marginLeft: "0.1em",
              }}
            >
              Naonow
            </Typography>
          </Link>
        </StyeldLinkGroup>
      </Box>
      <Typography color="white" variant="caption">
        Naonow@{new Date().getFullYear()} All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
