import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRef } from "react";

type Content = {
  image: string;
  small: {
    title: string;
  };
  large: {
    title: string;
    p1: string;
    p2: string;
  };
};

type Props = {
  content: Content;
  layer1?: boolean;
  onClick: (arg: any) => void;
};

const Card = ({ content, layer1, onClick }: Props) => {
  const { small, large, image } = content;
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div className={`card`} onClick={() => onClick(ref)} ref={ref}>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${image})`,
          backgroundSize: "cover",
        }}
        className={`content small ${layer1 ? "layer1" : "layer2"}`}
      >
        <Typography
          variant="h6"
          fontFamily="ReenieBeanie"
          fontSize={small.title === "International" ? "2.8rem" : "3rem"}
        >
          {small.title}
        </Typography>
      </div>
      <div className={`content large ${layer1 ? "layer1" : "layer2"}`}>
        <Typography
          variant="h3"
          sx={{
            fontSize: 14,
            fontWeight: 600,
            marginBottom: 2,
          }}
        >
          {large.title}
        </Typography>
        <Typography
          variant="body1"
          lineHeight={1}
          fontSize={12}
          mb={2}
          p={"0 10px"}
        >
          {large.p1}
        </Typography>
        <Box
          sx={{
            backgroundImage: `url(${image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: 140,
            marginBottom: 2,
          }}
        />
        <Typography
          variant="body1"
          lineHeight={1}
          fontSize={12}
          fontWeight={600}
          p={"0 10px"}
        >
          {large.p2}
        </Typography>
      </div>
    </div>
  );
};
export default Card;
