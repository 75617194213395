import { useEffect } from "react";
import Header from "./Header";
import famSvg from "./assets/fam.svg";
import techSvg from "./assets/tech.svg";
import handlebarSvg from "./assets/handle_bar.svg";
import Carousal from "./Carousal";
import Footer from "./Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function enableHeroClipping() {
  const hero = document.getElementById("hero-section");
  const handlebar = document.querySelector(".handlebar_wrapper");
  // init handlebar position
  const { host } = window.location;
  const offset = host === "naonow.fun" ? 0.1 : 0.9;
  updateSlider({
    type: "mousemove",
    pageX: window.innerWidth * offset,
  } as MouseEvent);

  function updateSlider(e: MouseEvent | TouchEvent) {
    const rightImageContainer = hero!.querySelector(".hero-wrapper");
    const { width } = rightImageContainer!.getBoundingClientRect();
    const pageX =
      e.type === "mousemove"
        ? (e as MouseEvent).pageX
        : (e as TouchEvent).changedTouches[0].pageX;
    const position = (pageX / width) * 100;

    if (position < 100 && position > 0) {
      (
        hero!.querySelector(".hero-clipping-wrapper")! as HTMLDivElement
      ).style.width = `${position}%`;
      (
        hero!.querySelector(".handlebar_wrapper")! as HTMLDivElement
      ).style!.left = `${position}%`;
    }
  }

  handlebar!.addEventListener(
    "mousedown",
    function (e) {
      e.preventDefault();
      hero!.addEventListener("mousemove", updateSlider);
    },
    false
  );
  handlebar!.addEventListener("mouseup", function (e) {
    e.preventDefault();
    hero!.removeEventListener("mousemove", updateSlider);
  });

  handlebar!.addEventListener(
    "touchmove",
    function (e) {
      e.preventDefault();
      hero!.addEventListener("touchmove", updateSlider);
    },
    false
  );
  handlebar!.addEventListener("touchend", function (e) {
    e.preventDefault();
    hero!.removeEventListener("touchmove", updateSlider);
  });
}

const useHeroClipping = () => {
  useEffect(() => {
    enableHeroClipping();
  }, []);
};

function Slogan({ isClipLayer }: { isClipLayer?: boolean }) {
  return (
    <Box className="slogan">
      <Typography
        variant="h1"
        sx={{
          color: (theme) =>
            isClipLayer
              ? theme.palette.common.white
              : theme.palette.common.black,
        }}
      >
        Learn, Share & Play with your
      </Typography>
      {isClipLayer ? (
        <img className="text-svg tech" src={techSvg} alt="tech" />
      ) : (
        <img className="text-svg family" src={famSvg} alt="family" />
      )}
    </Box>
  );
}

function App() {
  useHeroClipping();
  return (
    <div className="App">
      <div id="hero-section">
        <Box className="hero-wrapper">
          <Header />
          <Slogan />
          <Carousal layer2 />
          <Footer />
        </Box>
        <Box className="hero-clipping-wrapper">
          <Box className="hero-wrapper">
            <Header />
            <Slogan isClipLayer />
            <Carousal layer1 />
            <Footer />
          </Box>
        </Box>
        <div className="handlebar_wrapper">
          <img
            src={handlebarSvg}
            loading="lazy"
            alt=""
            className="handlebar_image"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
