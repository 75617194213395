import ky from "ky";

const apiClient = ky.create({
  prefixUrl:
    process.env.NODE_ENV === "production"
      ? `https://naonow.tech/api/`
      : `http://${window.location.hostname}:8000`,
});

export const sendEmail = async (email: string) => {
  const response = await apiClient.post("contact", {
    json: { email },
    mode: "cors",
  });
  return response.text();
};
