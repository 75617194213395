import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";
import Card from "./Card";

import funPic1 from "./assets/carousal/fun/Fun1_boardgameswall.jpg";
import funPic2 from "./assets/carousal/fun/Fun2_internationalplayers.jpg";
import funPic3 from "./assets/carousal/fun/Fun3_firstroyalgame.jpg";
import funPic4 from "./assets/carousal/fun/Fun4_noisybranstorming.jpg";
import funPic5 from "./assets/carousal/fun/Fun5_innovativecity.jpg";

import techPic1 from "./assets/carousal/tech/Tech1_boardgamesplaytest.jpeg";
import techPic2 from "./assets/carousal/tech/Tech2_worldwidecollection.jpeg";
import techPic3 from "./assets/carousal/tech/Tech3_fusiontemporality.jpg";
import techPic4 from "./assets/carousal/tech/Tech4_noisyattractions.png";
import techPic5 from "./assets/carousal/tech/Tech5_Ourselves.jpeg";

const CAROUSAL_DATA_LAYER_1 = [
  {
    image: funPic1,
    small: {
      title: "Ludotheque",
    },
    large: {
      title: "Boardgames wall",
      p1: "Noanow is an itinerant board-game library, to bring fun, challenges and social animation into your place of predilection. We share exclusively and extensively Board-games from all over the world to let you learn what you need and play only with your relatives and selected friends.",
      p2: "We infamously posses the largest private collection of international board-games in Shenzhen.",
    },
  },
  {
    image: funPic2,
    small: {
      title: "International",
    },
    large: {
      title: "International players",
      p1: "We are a team from all over the world, with different backgrounds but having always in common the passion of the board-games, the expertise of the facilitation and the respect of the core values of Naonow: To be always a friendly player, professional facilitator and innovative thinker.",
      p2: "We unsurprisingly come from all over the world including China, in order to serve you the best of it.",
    },
  },
  {
    image: funPic3,
    small: {
      title: "Culture",
    },
    large: {
      title: "First royal game",
      p1: "We use board-games rather than books, because they have a longer history, closer to our human society and allow more senses to be involve into experimenting them. As the books they are inspired by life, full of creativity, made over years of work and proof-tested before being published.",
      p2: "We proudly introduce to you a multi millennium cultural tool for learning actual social life tricks.",
    },
  },
  {
    image: funPic4,
    small: {
      title: "Creativity",
    },
    large: {
      title: "Noisy brainstorming",
      p1: "We elevate the art of the board-games into another and new dimension: the learn, share and play. Inspired by neuro-science new brain discoveries, the most recents theories of educational psychology and more general evolution of social behaviors and understandings across the world.",
      p2: "We seriously play games as an edutainment for any kind of audience, from 6 to 106 years old.",
    },
  },
  {
    image: funPic5,
    small: {
      title: "Shenzhen",
    },
    large: {
      title: "Innovative city",
      p1: "We are located in Shenzhen because that where everything happens first here in China and in the world. The exclusive and innovative facilitating program of Naonow couldn’t fit in a better place with open minded and hard working people all around to be able to develop into reality.",
      p2: "We humbly settle down in the most futuristic city to deliver the most promising edutainment experience.",
    },
  },
];

const CAROUSAL_DATA_LAYER_2 = [
  {
    image: techPic1,
    small: {
      title: "Ludotheque",
    },
    large: {
      title: "Boardgames play test",
      p1: "From our other departments and company history we keep a strong and active root into the international board-game community to ensure the freshness and the solidity of our points. Without our other friendly community of scientists and engineers, it’s hard to stay up-to-date. We know how to surround ourselves with countless motivated fans and friends.",
      p2: "We know how to surround ourselves by countless motivated fans and friends.",
    },
  },
  {
    image: techPic2,
    small: {
      title: "International",
    },
    large: {
      title: "Worldwide collection",
      p1: "Our game publishers and suppliers, our artists and engineers, our clients and partners are from all over the world. And Naonow team as well! They will all help you find the best way to promote your idea and product to the public through the ludic world of board games. We unsurprisingly share the same interest as you: getting your brand known and used everywhere.",
      p2: "We unsurprisingly share the same interest as you: getting your brand known and used everywhere.",
    },
  },
  {
    image: techPic3,
    small: {
      title: "Culture",
    },
    large: {
      title: "Fusion temporality",
      p1: "We use hi-tech patents, components, and products to merge them with the highly ever-trendy board games and bring to the public the next generation of social entertainment in real life. The tradition grants longevity, the games bring smiles, and tech ensures differentiation. We proudly work with hi-tech companies, researchers, and startups while our clients are families.",
      p2: "We proudly work with hi-tech companies, researchers, and startups while our clients are families.",
    },
  },
  {
    image: techPic4,
    small: {
      title: "Creativity",
    },
    large: {
      title: "Noisy attractions",
      p1: "We are not afraid of advanced technology, we use it carefully to better imagine the evolution of our own ideas, products, and market. Naonow takes its source from the noisy brainstorms where ideas form themselves from the apparent and relative chaos. We are rewarded for our design as we know how to be genuine even today.",
      p2: "We are rewarded for our design as we know how to be genuine even today.",
    },
  },
  {
    image: techPic5,
    small: {
      title: "Shenzhen",
    },
    large: {
      title: "Ourselves",
      p1: "We are a Sino-French Joint Venture, registered in the French-tech directory, located in Shenzhen since 2016 because it's where everything happens first here in China and then in the world. At Naonow, we do 3 things: B2B Hi-tech design, B2C Serious-Fun Coaching, and Ludic events organizer. We are young and disruptive, low and high-tech, meaningful and impactful.",
      p2: "We are young and disruptive, low and high-tech, meaningful and impactful.",
    },
  },
];

const Carousal = ({
  layer1,
  layer2,
}: {
  layer1?: boolean;
  layer2?: boolean;
}) => {
  const onCardClicked = (ref: any) => {
    ref.current?.classList.toggle("clicked");
  };

  return (
    <>
      <Swiper
        centeredSlides
        loopAdditionalSlides={2}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 5,
          },
          1500: {
            slidesPerView: 5,
          },
        }}
        loop
        onActiveIndexChange={() => {
          const cards = document.querySelectorAll(".card");
          cards.forEach((card) => {
            card.classList.remove("clicked");
          });
        }}
      >
        {layer1 &&
          [...CAROUSAL_DATA_LAYER_2, ...CAROUSAL_DATA_LAYER_2].map(
            (item, idx) => (
              <SwiperSlide key={idx}>
                <Card layer1 content={item} onClick={onCardClicked} />
              </SwiperSlide>
            )
          )}
        {layer2 &&
          [...CAROUSAL_DATA_LAYER_1, ...CAROUSAL_DATA_LAYER_1].map(
            (item, idx) => (
              <SwiperSlide key={idx}>
                <Card content={item} onClick={onCardClicked} />
              </SwiperSlide>
            )
          )}
      </Swiper>
    </>
  );
};

export default Carousal;
